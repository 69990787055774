<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <template #header>
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <div class="mt-2 font-italic">
                            Última actualización el <strong>{{ serviceOrder.updatedDate ? serviceOrder.updatedDate : serviceOrder.createdDate }}</strong> por <strong>{{ serviceOrder.updatedBy ? serviceOrder.updatedBy : serviceOrder.createdBy }}</strong>
                        </div>
                    </div>
                </div>
            </template>
            <b-row class="mt-2">
                <b-col class="col-md-6 col-lg-6">
                    <b-table-simple responsive bordered striped>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 25%">Registrado</b-th>
                                <b-td>{{ serviceOrder.createdDate ? serviceOrder.createdDate : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Elaborado por</b-th>
                                <b-td>{{ serviceOrder.createdBy ? serviceOrder.createdBy : 'Sin registro' }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col class="col-md-6 col-lg-6 text-right">
                    <h1 class="font-weight-bolder">Orden de servicio</h1>
                    <h1 class="text-navy font-weight-bolder">{{ serviceOrder.folio }}</h1>
                    <h3 class="mb-3">
                        <b-badge variant="warning" class="button-sticky-right" v-if="serviceOrder.status === 'DRAFT'">Borrador</b-badge>
                        <b-badge variant="info" class="button-sticky-right" v-if="serviceOrder.status === 'OPEN'">Abierta</b-badge>
                        <b-badge variant="primary" class="button-sticky-right" v-if="serviceOrder.status === 'FINISHED'">Terminada</b-badge>
                        <b-badge variant="danger" class="button-sticky-right" v-if="serviceOrder.status === 'CANCELLED'">Cancelada</b-badge>
                    </h3>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="4">Servicio</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 50%" colspan="2">Datos</b-th>
                                <b-th style="width: 50%" colspan="2"></b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 10%">Fecha</b-th>
                                <b-td>
                                    <template v-if="serviceOrder.startDate === serviceOrder.endDate">
                                        {{ serviceOrder.startDate }}
                                    </template>
                                    <template v-else>
                                        {{ serviceOrder.startDate }} - {{ serviceOrder.endDate }}
                                    </template>
                                    <template v-if="serviceOrder.durationType === 'SPECIFIC'">
                                        <span v-if="serviceOrder.startTime">{{ serviceOrder.startTime.split(':').slice(0,2).join(':') }} - {{ serviceOrder.endTime.split(':').slice(0,2).join(':') }}</span>
                                        <span style="color: #e57523" v-if="!serviceOrder.startTime || !serviceOrder.endTime">Horario no definido</span>
                                    </template>
                                </b-td>
                                <b-th style="width: 10%">Operador</b-th>
                                <b-td>{{ serviceOrder.operatorName }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Duración</b-th>
                                <b-td>{{ serviceOrder.durationType === 'ALL' ? 'Todo el dia' : 'Horario especifico' }}</b-td>
                                <b-th>Vehículo</b-th>
                                <b-td>{{ serviceOrder.vehicle.brand }} {{ serviceOrder.vehicle.model }} [{{ serviceOrder.vehicle.licensePlate }}]</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Servicio</b-th>
                                <b-td>{{ serviceOrder.name }}</b-td>
                                <b-th></b-th>
                                <b-td></b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Tipo</b-th>
                                <b-td>{{ serviceOrder.type === 'COLLECTIVE' ? 'Colectivo' : 'Exclusivo' }}</b-td>
                                <b-th></b-th>
                                <b-td></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="7">Pasajeros</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 9%">Reservación</b-th>
                                <b-th class="text-center" style="width: 6%">PAX</b-th>
                                <b-th>Tipo</b-th>
                                <b-th>Nombre</b-th>
                                <template v-if="serviceOrder.entityType === 'TOUR'">
                                    <b-th style="width: 12%">Recoger en hotel</b-th>
                                    <b-th style="width: 12%">Dejar en hotel</b-th>
                                </template>
                                <template v-if="serviceOrder.entityType === 'TRANSFER'">
                                    <b-th style="width: 15%">Recoger/Dejar en hotel</b-th>
                                    <b-th style="width: 12%">Hora del vuelo</b-th>
                                    <b-th style="width: 12%">Número del vuelo</b-th>
                                </template>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(passenger, index) in serviceOrder.passengers" :key="passenger.guid">
                                <b-td>
                                    <router-link :to="{ name: 'BusinessOrderShow', params: { id: passenger.businessOrderId }}" target="_blank">{{ passenger.folio }}</router-link>
                                </b-td>
                                <b-td class="text-center">{{ index + 1 }}</b-td>
                                <b-td>{{ passenger.type === 'ADULT' ? 'Adulto' : 'Niño' }}</b-td>
                                <b-td>{{ passenger.name }} {{ passenger.firstLastName }}</b-td>
                                <template v-if="serviceOrder.entityType === 'TOUR'">
                                    <b-td>{{ passenger.hotel1 }}</b-td>
                                    <b-td>{{ passenger.hotel2 }}</b-td>
                                </template>
                                <template v-if="serviceOrder.entityType === 'TRANSFER'">
                                    <b-td>{{ passenger.hotel1 }}</b-td>
                                    <b-td>{{ passenger.flightSchedule }}</b-td>
                                    <b-td>{{ passenger.flightNumber }}</b-td>
                                </template>
                            </b-tr>
                        </b-tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-th>Adultos</b-th>
                                <b-th class="text-right" style="width: 6%;">{{ serviceOrder.adultsQty }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th>Niños</b-th>
                                <b-th class="text-right" style="width: 6%;">{{ serviceOrder.childrenQty }}</b-th>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="7">Gastos</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 14%">Concepto</b-th>
                                <b-th style="width: 20%">Observaciones</b-th>
                                <b-th>Registrado por</b-th>
                                <b-th>Fecha de registro</b-th>
                                <b-th style="text-align: right; width: 10%">Cantidad</b-th>
                                <b-th style="text-align: right; width: 10%">Costo Unitario</b-th>
                                <b-th style="text-align: right; width: 10%">Subtotal</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="expense in serviceOrder.expenseList" :key="expense.guid" :class="{'text-danger': expense.isCancelled }" :style="[expense.isCancelled ? {'text-decoration': 'line-through'} : '']">
                                <b-td><span class="text-primary" v-if="expense.isExtra"><strong>Extra - </strong></span>{{ expense.name }}</b-td>
                                <b-td>{{ expense.notes }}</b-td>
                                <b-td>{{ expense.createdBy }}</b-td>
                                <b-td>{{ formatDateTime(expense.createdDate) }}</b-td>
                                <b-td style="text-align: right">{{ expense.qty }}</b-td>
                                <b-td style="text-align: right">${{ formatCurrency(expense.unitPrice) }}</b-td>
                                <b-td style="text-align: right">${{ formatCurrency(expense.total) }}</b-td>
                            </b-tr>
                            <b-tr v-if="serviceOrder.expenseList.length === 0">
                                <b-td colspan="7" class="text-center font-weight-bold">Sin registros</b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-th colspan="6" style="text-align: right">Total</b-th>
                                <b-th style="text-align: right">${{ formatCurrency(expenseTotal) }}</b-th>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template v-if="serviceOrder.status !== 'DRAFT'">
                <hr>
                <b-row class="mt-4">
                    <b-col>
                        <b-table-simple responsive bordered striped class="z-index-1">
                            <b-thead>
                                <b-tr>
                                    <b-th class="table-header-color" colspan="10">Cuentas por pagar</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th>Folio</b-th>
                                    <b-th>Proveedor</b-th>
                                    <b-th>Importe</b-th>
                                    <b-th>Pagado</b-th>
                                    <b-th>Saldo</b-th>
                                    <b-th>Estatus</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="accountRp in serviceOrder.accountsRp" :key="'account-rp-row-' + accountRp.id">
                                    <b-td><router-link :to="{ name: 'AccountRpShow', params: { id: accountRp.id }}" target="_blank">{{ accountRp.folio }}</router-link></b-td>
                                    <b-td>{{ accountRp.supplier }}</b-td>
                                    <b-td class="text-right">$ {{ formatCurrency(accountRp.amount) }}</b-td>
                                    <b-td class="text-right">$ {{ formatCurrency(accountRp.paidReceiveAmount) }}</b-td>
                                    <b-td class="text-right">$ {{ formatCurrency(accountRp.balance) }}</b-td>
                                    <b-td class="text-center">
                                        <b-badge variant="warning" v-if="accountRp.status === 'BALANCE'">Por saldar</b-badge>
                                        <b-badge variant="primary" v-if="accountRp.status === 'PAID'">Pagada</b-badge>
                                        <b-badge variant="danger" v-if="accountRp.status === 'CANCELLED'">Cancelada</b-badge>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col>
                        <b-table-simple responsive bordered striped class="z-index-1">
                            <b-thead>
                                <b-tr>
                                    <b-th class="table-header-color" colspan="10">Pagos</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-center" style="width: 4%">#</b-th>
                                    <b-th style="width: 10%; min-width: 150px;">Fecha de pago</b-th>
                                    <b-th>Folio</b-th>
                                    <b-th style="width: 12%; min-width: 150px;">Método</b-th>
                                    <b-th>Registrado por</b-th>
                                    <b-th>Fecha de registro</b-th>
                                    <b-th>Transacción</b-th>
                                    <b-th>Estatus</b-th>
                                    <b-th style="text-align: right; width: 12%; min-width: 90px; max-width: 120px;">Importe</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(payment, index) in serviceOrder.payments" :key="'payment-' + index" :class="{'text-danger': payment.status === 'CANCELLED'}" :style="[payment.status === 'CANCELLED' ? {'text-decoration': 'line-through'} : '']">
                                    <b-td class="text-center">{{ index + 1 }}</b-td>
                                    <b-td>{{ formatDate(payment.paymentDate) }}</b-td>
                                    <b-td><router-link :to="{ name: 'PaymentShow', params: { id: payment.id }}" target="_blank">{{ payment.folio }}</router-link></b-td>
                                    <b-td>
                                        <span v-if="payment.method === 'CREDIT_CARD'">Tarjeta de crédito</span>
                                        <span v-if="payment.method === 'DEBIT_CARD'">Tarjeta de débito</span>
                                        <span v-if="payment.method === 'CASH'">Efectivo</span>
                                        <span v-if="payment.method === 'CHECK'">Cheque</span>
                                        <span v-if="payment.method === 'TRANSFER'">Transferencia</span>
                                        <!--                                                    <span v-if="payment.method === 'OTHER'">Otros</span>-->
                                    </b-td>
                                    <b-td>{{ payment.createdBy }}</b-td>
                                    <b-td>{{ formatDateTime(payment.createdDate) }}</b-td>
                                    <b-td>
                                        {{ payment.transactionFolio }}
                                        <b-button size="sm" variant="outline-primary" v-b-tooltip.hover.top title="Imprimir"
                                                  @click="transactionPage(payment)" v-if="payment.accountsInTransaction > 1">
                                            <font-awesome-icon icon="print"></font-awesome-icon>
                                        </b-button>
                                    </b-td>
                                    <b-td>
                                        <span v-if="payment.status === 'TO_VERIFY'">Por verificar</span>
                                        <span v-if="payment.status === 'VERIFIED'">Verificado</span>
                                        <span v-if="payment.status === 'CANCELLED'">Cancelado</span>
                                    </b-td>
                                    <b-td style="text-align: right">${{ formatCurrency(payment.paidAmount) }}</b-td>
                                </b-tr>
                                <b-tr v-if="serviceOrder.payments.length === 0">
                                    <b-td colspan="10" class="text-center font-weight-bold">Sin registros</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </template>
            <template #footer>
                <div class="text-right">
                    <b-button class="mr-2" variant="outline-secondary" :to="{ name: 'ServiceOrderIndex' }"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button v-if="serviceOrder.status !== 'DRAFT' && serviceOrder.status !== 'CANCELLED'" class="mr-2" variant="primary" @click="expensesPayment"> Pagar gastos</b-button>
                    <b-button v-if="serviceOrder.status !== 'CANCELLED'" class="mr-2" variant="primary" v-b-modal="'expense-modal'"><font-awesome-icon icon="dollar-sign"></font-awesome-icon> Gastos</b-button>
                    <b-button variant="primary" @click="servicePage"><font-awesome-icon icon="print"></font-awesome-icon> Imprimir</b-button>
              </div>
            </template>

            <!-- expense modal -->
            <template>
                <b-modal id="expense-modal" centered size="xl" title="Registro de Gastos" hide-header-close no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(saveExpenses)" id="expense-form">
                            <b-button class="button-sticky-right mb-2" @click="addExpense"><font-awesome-icon icon="plus"></font-awesome-icon> Nuevo</b-button>
                            <b-row>
                                <b-col>
                                    <b-table-simple responsive bordered striped>
                                        <b-thead>
                                            <b-tr>
                                                <b-th style="width: 14%">Concepto</b-th>
                                                <b-th style="width: 20%">Observaciones</b-th>
                                                <b-th>Registrado por</b-th>
                                                <b-th>Fecha de registro</b-th>
                                                <b-th style="text-align: right; width: 10%">Cantidad</b-th>
                                                <b-th style="text-align: right; width: 10%">Costo Unitario</b-th>
                                                <b-th style="text-align: right; width: 10%">Subtotal</b-th>
                                                <b-th class="text-center">Acciones</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="(expense, index) in serviceOrder.expenseList" :key="'expense-' + index"
                                                  :class="{'text-danger': expense.isCancelled}" :style="[expense.isCancelled ? {'text-decoration': 'line-through'} : '']">
                                                <template v-if="expense.id">
                                                    <b-th>{{ expense.name}}</b-th>
                                                    <b-td>{{ expense.notes}}</b-td>
                                                    <b-td>{{ expense.createdBy }}</b-td>
                                                    <b-td>{{ formatDateTime(expense.createdDate) }}</b-td>
                                                    <b-td class="text-right">{{ expense.qty }}</b-td>
                                                    <template v-if="expense.name === 'Gasolina'">
                                                        <b-td>
                                                            <b-form-input v-model="expense.unitPrice" autocomplete="false"
                                                                          placeholder="Costo" style="text-align: right"></b-form-input>
                                                        </b-td>
                                                    </template>
                                                    <template v-else>
                                                        <b-td class="text-right">$ {{ formatCurrency(expense.unitPrice) }}</b-td>
                                                    </template>
                                                    <b-td class="text-right">$ {{ formatCurrency(expense.total) }}</b-td>
                                                </template>
                                                <template v-if="!expense.id">
                                                    <b-td>
                                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                                            <b-form-input v-model="expense.name" autocomplete="false" placeholder="Concepto"
                                                                          :state="errors[0] ? false : null"></b-form-input>
                                                        </ValidationProvider>
                                                    </b-td>
                                                    <b-td>
                                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                                            <b-form-input v-model="expense.notes" autocomplete="false" placeholder="Observaciones"
                                                                          :state="errors[0] ? false : null"></b-form-input>
                                                        </ValidationProvider>
                                                    </b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td class="text-right">{{ expense.qty }}</b-td>
                                                    <b-td>
                                                        <ValidationProvider rules="required|double|min_value:0" v-slot="{ errors }">
                                                            <b-form-input v-model="expense.unitPrice" autocomplete="false" placeholder="Costo unitario"
                                                                          class="text-right" :state="errors[0] ? false : null"></b-form-input>
                                                        </ValidationProvider>
                                                    </b-td>
                                                    <b-td class="text-right">$ {{ formatCurrency(expense.total) }}</b-td>
                                                </template>
                                                <b-td>
                                                    <b-button variant="outline-danger" @click="deleteExpense(expense)" v-b-tooltip.hover.top
                                                              title="Eliminar" v-if="expense.isExtra && !expense.isCancelled">
                                                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                                    </b-button>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th colspan="6" class="text-right">Total</b-th>
                                                <b-th class="text-right">$ {{ formatCurrency(expenseTotal) }}</b-th>
                                                <b-th></b-th>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button variant="outline-secondary" @click="cancelExpenses" class="mr-2">Cancelar</b-button>
                            <b-button type="submit" form="expense-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                        </div>
                    </template>
                </b-modal>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { paymentExpenseUrl, serviceOrderExpenseUrl, serviceOrderUrl, servicePageUrl, transactionPageUrl } from '@routes';
import * as constants from '@constants';
import Utilities, { formatCurrency, formatDate, formatDateTime } from '../../js/utilities';
import { Passenger } from '../../models/passenger';
import { Expense } from '../../models/expense';
import { ServiceOrderItem } from '../../models/serviceOrderItem';
import { Payment } from '../../models/payment';
import { required, double, min_value } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

extend('double', {
    ...double,
    message: 'Ingrese solo números y decimales'
});

extend('min_value', {
    ...min_value,
    message: 'Ingrese un valor mayor que 0'
});

export default {
    data() {
        return {
            serviceOrder: {
                id: 0,
                serviceId: 0,
                folio: '',
                createdDate: '',
                createdBy: '',
                updatedDate: '',
                updatedBy: '',
                operatorName: '',
                startDate: '',
                endDate: '',
                adultsQty: 0,
                childrenQty: 0,
                expenseList: [],
                vehicle: {},
                passengers: [],
                entityType: '',
                serviceOrderItems: [],
                payments: [],
                accountsRp: []
            },
            breadcrumb: {
                title: 'Ordenes de servicio',
                path: [
                    {
                        name: 'ServiceOrderIndex',
                        text: 'Orden de servicio'
                    }
                ]
            },
            expenseListCache: [],
            isLoading: false
        };
    },
    created() {
        this.loadData();

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `Detalle`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    mounted() {
        this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
            if (modalId === 'expense-modal') {
                this.expenseListCache = [];
                this.serviceOrder.expenseList.forEach(item => {
                    let expenseList =  { ...item };
                    this.expenseListCache.push(expenseList);
                });
            }
        });
    },
    methods: {
        formatDate,
        formatDateTime,
        formatCurrency,
        loadData() {
            this.isLoading = true;

            this.axios.get(serviceOrderUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.serviceOrder = response.data.serviceOrder;

                    response.data.passengers.forEach(passengerItemData => {
                        let serviceOrderPassengers = JSON.parse(passengerItemData.passengersData);
                        this.serviceOrder.entityType = passengerItemData.entityType;

                        serviceOrderPassengers.forEach(passengerData => {
                            let passenger = Passenger.create(passengerData.type);

                            passenger.id = passengerData.id;
                            passenger.name = passengerData.name;
                            passenger.firstLastName = passengerData.firstLastName;
                            passenger.secondLastName = passengerData.secondLastName;
                            passenger.identifier = passengerData.id.toString();
                            passenger.selected = passengerData.selected;
                            passenger.hotel1 = passengerItemData.hotel1;
                            passenger.hotel2 = passengerItemData.hotel2;
                            passenger.flightNumber = passengerItemData.flightNumber;
                            passenger.flightSchedule = passengerItemData.flightSchedule;
                            passenger.folio = passengerItemData.folio;
                            passenger.businessOrderId = passengerItemData.businessOrderId;

                            if (passenger.selected && passengerData.serviceOrderId == this.serviceOrder.id) {
                                this.serviceOrder.passengers.push(passenger);
                            }
                        });
                    });

                    response.data.serviceOrderItems.forEach(data => {

                        let serviceOrderItem = ServiceOrderItem.create();

                        serviceOrderItem.businessOrderItem = JSON.parse(JSON.stringify(data));
                        serviceOrderItem.adults = data.adults;
                        serviceOrderItem.children = data.children;
                        serviceOrderItem.id = data.serviceOrderItemId;

                        this.serviceOrder.serviceOrderItems.push(serviceOrderItem);
                    });

                    response.data.expenseList.forEach(expenseListItemData => {
                        let expenseListItem = Expense.create();

                        expenseListItem.id = expenseListItemData.id;
                        expenseListItem.name = expenseListItemData.name;
                        expenseListItem.category = expenseListItemData.category;
                        expenseListItem.unitPrice = expenseListItemData.unitPrice;
                        expenseListItem.qty = expenseListItemData.qty;
                        expenseListItem.total = expenseListItemData.total;
                        expenseListItem.notes = expenseListItemData.notes;
                        expenseListItem.createdBy = expenseListItemData.createdBy;
                        expenseListItem.createdDate = expenseListItemData.createdDate;
                        expenseListItem.isCancelled = expenseListItemData.isCancelled;
                        expenseListItem.isExtra = expenseListItemData.isExtra;
                        expenseListItem.supplierId = expenseListItemData.supplierId;

                        this.serviceOrder.expenseList.push(expenseListItem);
                    });

                    response.data.payments.forEach(paymentData => {
                        let payment = Payment.create();

                        payment.id = paymentData.id;
                        payment.folio = paymentData.folio;
                        // payment.objectType = paymentData.objectType;
                        // payment.objectId = paymentData.objectId;
                        payment.type = paymentData.type;
                        payment.amount = paymentData.amount;
                        payment.paidAmount = paymentData.paidAmount;
                        payment.balance = paymentData.balance;
                        payment.businessEntity = {
                            id: paymentData.businessEntityId,
                            type: paymentData.businessEntityType,
                            legalName: paymentData.businessEntityLegalName,
                            rfc: paymentData.businessEntityRfc,
                            phone1: paymentData.businessEntityPhone1,
                            email1: paymentData.businessEntityEmail1
                        };
                        payment.method = paymentData.method;
                        payment.reference = paymentData.reference;
                        payment.cancelNotes = paymentData.cancelNotes;
                        payment.createdBy = paymentData.createdBy;
                        payment.createdDate = paymentData.createdDate;
                        payment.paymentDate = paymentData.paymentDate;
                        payment.isVerified = paymentData.isVerified;
                        payment.transactionId = paymentData.transactionId;
                        payment.transactionFolio = paymentData.transactionFolio;
                        payment.status = paymentData.status;
                        payment.accountsInTransaction = paymentData.accountsInTransaction;

                        if (payment.status !== 'CANCELLED') {
                            this.paymentsAmount = this.paymentsAmount + parseFloat(payment.paidAmount);
                        }

                        this.serviceOrder.payments.push(payment);
                    });
                }

                this.$nextTick(() => this.isLoading = false);
            });
        },
        updateExpensesQty() {
            this.serviceOrder.expenseList.forEach(expense => {
                if (expense.name === 'Entrada Adulto' || expense.name === 'Tarifa Adulto') {
                    expense.qty = this.serviceOrder.adultsQty;
                }

                if (expense.name === 'Entrada Niño' || expense.name === 'Tarifa Niño') {
                    expense.qty = this.serviceOrder.childrenQty;
                }
            });
        },
        saveExpenses() {
            this.axios.post(serviceOrderExpenseUrl(), this.serviceOrder).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'success'
                    });

                    this.$nextTick(() => {
                        this.$bvModal.hide('expense-modal');
                    });

                    this.loadData();
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        addExpense() {
            this.serviceOrder.expenseList.push(Expense.create());
        },
        deleteExpense(expense) {
            if (expense.id > 0) {
                expense.isCancelled = true;
            } else {
                let index = this.serviceOrder.expenseList.findIndex(expenseItem => expenseItem.guid === expense.guid);

                if (index >= 0) {
                    this.serviceOrder.expenseList.splice(index, 1);
                }
            }
        },
        cancelExpenses() {
            this.serviceOrder.expenseList.splice(0);

            this.expenseListCache.forEach(item => {
                this.serviceOrder.expenseList.push(item);
            });

            this.$nextTick(() => {
                this.$bvModal.hide('expense-modal');
            });
        },
        expensesPayment() {
            let title = '¿Deseas generar los pagos de los gastos actuales?';

            this.$swal({
                title: title,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.axios.post(paymentExpenseUrl(), this.serviceOrder).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.loadData();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        servicePage() {
            this.isLoading = true;
            this.axios.post(servicePageUrl(), this.serviceOrder).then(response => {
                let link = document.createElement('a');

                document.body.appendChild(link);

                link.setAttribute('type', 'application/pdf');
                link.href = 'data:application/pdf;base64,' + response.data.file;
                link.download = this.serviceOrder.folio;
                link.click();

                document.body.removeChild(link);
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al generar la hoja de servicio.', {
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        transactionPage(paymentItem) {
            this.isLoading = true;
            this.axios.post(transactionPageUrl(), { transactionId: paymentItem.transactionId }).then(response => {
                let link = document.createElement('a');

                document.body.appendChild(link);

                link.setAttribute('type', 'application/pdf');
                link.href = 'data:application/pdf;base64,' + response.data.file;
                link.download = paymentItem.transactionFolio;
                link.click();

                document.body.removeChild(link);
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al generar comprobante de transacción.', {
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
        expenseTotal() {
            let expenseTotal = 0;

            this.serviceOrder.expenseList.forEach(expense => {
                if (!expense.isCancelled) {
                    let unitPrice = Utilities.isPositiveNumber(expense.unitPrice) ? expense.unitPrice : 0;

                    expense.total = unitPrice * expense.qty;
                    expenseTotal += parseFloat(expense.total);
                }
            });

            return expenseTotal;
        }
    }
};

</script>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: transform 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        transform: translateX(-120%);
    }

    .fade-enter-to,
    .fade-leave {
        transform: translateX(0);
    }

    .custom-control-label {
        color: #ffffff !important;
    }
</style>